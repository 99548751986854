@use 'mixins';

.ant-popover {
  max-width: 500px;

  @include mixins.mobile {
    width: 95vw;
    margin-right: 10px;
  }

  &-title {
    padding: 10px var(--lg-padding);
    color: var(--Text-Strong);
    font-size: var(--default-font-size);
  }

  &-arrow {
    &-content {
      &::before {
        width: 24px;
        height: 24px;
      }
    }
  }

  &-inner {
    border-radius: var(--md--border-radius);
    border: 0 solid var(--Stroke-Weak);
    border-left: 4px solid var(--primary-color);
    background: #fff;

    &-content {
      padding: var(--lg-padding);
      color: var(--Text-Strong);

      .ant-popover-message {
        font-size: 16px;
        color: var(--Text-Weak);
      }

      .ant-popover-buttons {
        display: flex;
      }
    }
  }

  &--alert {
    .ant-popover-arrow-content {
      &::before {
        background: var(--Fill-Error-weak);
        border: 0 solid var(--Stroke-Error-weak);
      }
    }

    .ant-popover-inner {
      border: 0 solid var(--Stroke-Error-weak);
      border-left: 4px solid var(--Stroke-Error-strong);
      background: var(--Fill-Error-weak);
    }
  }
}
