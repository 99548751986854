.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled),
.ant-input-affix-wrapper,
.ant-input {
  height: var(--md-height);
  font-size: var(--md-font-size);
  border-radius: var(--md--border-radius);
  border: 1px solid var(--Stroke-Strong);

  &:is(:hover, :focus) {
    border-color: var(--secondary-color);
    box-shadow: 0 0 0 2px var(--secondary-color-20);
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;

  .ant-input-affix-wrapper {
    box-shadow: 0 0 0 2px var(--secondary-color-20);
  }
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
  height: var(--md-height);

  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input,
  .ant-input-status-error:not(.ant-input-disabled):not(
      .ant-input-borderless
    ).ant-input:hover,
  .ant-input {
    background: transparent;
    height: calc(var(--md-height) - 2px);

    &:is(:hover, :focus) {
      box-shadow: none;
    }
  }
}
