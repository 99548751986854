.ant-picker {
  height: var(--md-height);
  font-size: var(--md-font-size);
  border-radius: var(--md--border-radius);
  border: 1px solid var(--Stroke-Strong);
  width: 100%;

  &-input {
    > input {
      font-size: var(--md-font-size);
      color: inherit;
    }
  }

  &:hover {
    border-color: var(--secondary-color);
  }
}

.ant-picker-panel-container {
  border-radius: var(--md--border-radius);

  .ant-picker-cell-today {
    .ant-picker-cell-inner::before {
      border-color: var(--primary-color);
    }
  }

  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background: var(--primary-color);
    }
  }

  a,
  button {
    cursor: pointer;
    color: var(--primary-color);

    &:hover {
      color: var(--secondary-color);
    }

    &.ant-btn-primary {
      color: #fff;

      &:hover {
        color: #fff;
      }
    }
  }
}
