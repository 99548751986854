.ant-drawer {
  &-mask {
    background: var(--Fill-Overlay);
    backdrop-filter: blur(1px);
  }

  &-header {
    &-title {
      .ant-drawer-close {
        position: absolute;
        right: var(--lg-padding);
        margin-right: unset;
      }

      .ant-drawer-title {
        font-size: var(--h4-font-size);
        font-weight: 600;
      }
    }
  }

  &-footer {
    display: flex;
    gap: var(--md-padding);
    padding: var(--lg-padding) var(--xl-padding);

    &:has(div[nzJustify]) {
      display: block;
    }
  }

  &-header,
  &-body,
  &-footer {
    padding: var(--lg-padding) var(--xl-padding);
  }
}
