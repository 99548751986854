.ant-alert {
  border-radius: var(--md--border-radius);

  &-message {
    color: var(--Text-Strong);
    font-size: var(--default-font-size);
    font-weight: 500;
  }

  &-description {
    color: var(--Text-Weak);
    font-size: var(--default-font-size);
  }

  &-info {
    border-color: var(--Stroke-Information-weak);
    background: var(--Fill-Information-weak);
    border-left: 4px solid var(--Stroke-Information-strong);

    .ant-alert-icon {
      color: var(--Stroke-Information-strong);
    }
  }

  &-error {
    border-color: var(--Stroke-Error-weak);
    background: var(--Fill-Error-weak);
    border-left: 4px solid var(--Stroke-Error-strong);

    .ant-alert-icon {
      color: var(--Stroke-Error-strong);
    }
  }

  &-warning {
    border-color: var(--Yellow-solid-500);
    background: var(--Yellow-solid-80);
    border-left: 4px solid var(--Yellow-solid-1000);

    .ant-alert-icon {
      color: var(--Yellow-solid-1000);
    }
  }
}
