.ant-input-group-wrapper {
  .ant-input-group {
    .ant-input {
      // height: calc(var(--md-height) - 9px); //TODO: test this in Safari
      font-size: var(--md-font-size);
    }

    .ant-input-prefix {
      font-size: 20px;
      opacity: 0.5;

      .anticon {
        font-size: 22px;
      }
    }

    .ant-input-suffix {
      font-size: 16px;
    }

    .ant-input-affix-wrapper:not(:last-child) {
      border-top-left-radius: var(--md--border-radius);
      border-bottom-left-radius: var(--md--border-radius);
    }

    .ant-input-group-addon:last-child .ant-btn {
      border-radius: 0 var(--md--border-radius) var(--md--border-radius) 0;
    }
  }

  &[class*='lg'] {
    .ant-input-group {
      .ant-input {
        height: calc(var(--lg-height) - 9px);
      }

      .ant-input-prefix {
        font-size: 22px;
      }

      .ant-input-affix-wrapper:not(:last-child) {
        border-top-left-radius: var(--lg--border-radius);
        border-bottom-left-radius: var(--lg--border-radius);
      }

      .ant-input-group-addon:last-child .ant-btn {
        border-radius: 0 var(--lg--border-radius) var(--lg--border-radius) 0;
      }
    }
  }

  &[class*='sm'] {
    .ant-input-group {
      .ant-input {
        height: calc(var(--sm-height) - 9px);
      }

      .ant-input-prefix {
        font-size: 16px;
      }

      .ant-input-affix-wrapper:not(:last-child) {
        border-top-left-radius: var(--sm--border-radius);
        border-bottom-left-radius: var(--sm--border-radius);
      }

      .ant-input-group-addon:last-child .ant-btn {
        border-radius: 0 var(--sm--border-radius) var(--sm--border-radius) 0;
      }
    }
  }
}
