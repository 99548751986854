@use 'variables';
@use 'helpers';
@use 'generics';
@use 'components/index';

html,
body {
  background: var(--body-background-color);
  font-family: 'Satoshi', sans-serif;
  font-size: var(--default-font-size);
  color: var(--Text-Strong);
}
