.ant-input-number {
  height: var(--md-height);
  font-size: var(--md-font-size);
  border-radius: var(--md--border-radius);
  border: 1px solid var(--Stroke-Strong);
  width: 100%;

  &-handler-wrap {
    border-radius: 0 var(--md--border-radius) var(--md--border-radius) 0;
    width: 28px;
    right: 2px;

    .ant-input-number-handler .ant-input-number-handler-down-inner,
    .ant-input-number-handler .ant-input-number-handler-up-inner {
      vertical-align: -0.125em;
      right: 8px;
      font-size: 16px;
    }
  }

  &-input-wrap {
    input {
      height: var(--md-height);
    }
  }

  &:hover {
    border-color: var(--secondary-color);
  }
}
