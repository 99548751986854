@use 'mixins';

.ant-card {
  margin-bottom: var(--default-gap);
  border-radius: var(--card-border-radius);
  border: 1px solid var(--Stroke-Weak);
  background: #fff;
  font-size: 16px;

  @include mixins.box-shadow;

  &--info {
    background: var(--Teal-Dark-200);
  }

  &--alert {
    background: var(--Fill-Error-weak);
  }

  &.ant-card--faded {
    background: var(--Fill-Inverse-disabled);

    .ant-card-body {
      opacity: 0.8;
    }
  }

  &.ant-card--series {
    background: var(--Purple-Dark-100);
  }

  &.ant-card-head--series {
    .ant-card-head {
      background: var(--Purple-Dark-100);
    }
  }

  &-head {
    border-color: var(--Stroke-Weak);

    &-title {
      font-size: var(--h4-font-size);
      color: var(--Text-Weak);
      font-weight: 600;
      padding: var(--lg-padding) 0;

      @include mixins.mobile {
        padding: var(--mobile-padding) 0;
      }
    }
  }

  &-body {
    padding: var(--default-gap);

    @include mixins.mobile {
      padding: var(--mobile-padding);
    }

    .ant-card-footer {
      border-top: 1px solid var(--Stroke-Weak);
      padding: var(--lg-padding) var(--xl-padding);
      margin-inline: calc(var(--default-gap) * -1);
      transform: translateY(var(--xl-padding));
    }
  }
}
