.ant-radio {
  font-size: var(--default-font-size);
  top: 0.35rem;

  &-wrapper {
    font-size: var(--default-font-size);
  }

  &-inner {
    width: 24px;
    height: 24px;
    border-color: var(--Stroke-Strong);

    &:hover {
      border-color: var(--secondary-color);
    }
  }

  &-checked &-inner {
    background: var(--primary-color);

    &::after {
      background-color: #fff;
    }
  }
}
