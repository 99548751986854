@use 'mixins';

.ant-dropdown {
  .ant-dropdown-menu-item {
    &:has(.ant-menu-title-content a, .ant-menu-title-content button) {
      padding: 0;
    }

    .ant-menu-title-content {
      &:has(a, button) {
        display: block;
        width: 100%;
      }

      a,
      button {
        @include mixins.align-items-center;

        justify-content: flex-start;

        &:not([nzDanger]) {
          color: unset;
        }

        &.ant-btn-sm {
          height: unset;
        }

        border: none;
        background: none;
        box-shadow: none;
        width: 100%;
        padding: 5px 12px;

        span {
          margin-left: 0;
          font-weight: 400;
        }
      }

      .anticon,
      img {
        margin-right: 10px;
      }
    }
  }

  &-menu {
    border-radius: var(--md--border-radius);

    .ant-menu-title-content {
      a,
      button {
        height: 38px;

        span {
          font-size: var(--default-font-size);
        }
      }
    }
  }
}
