.ant-tag {
  border-radius: var(--tag-border-radius);
  padding: 1px var(--tag-padding);
  font-size: var(--small-font-size);
  display: inline-flex;
  background: var(--Fill-Weak);
  color: var(--Text-Strong);
  font-weight: 400;
  margin: 0 8px 8px 0;

  &:only-child {
    margin-bottom: 0;
  }

  span {
    font-size: var(--small-font-size);
  }

  > .anticon + span {
    margin-left: 5px;
    font-size: var(--small-font-size);
  }

  &[size='large'] {
    padding: 5px var(--sm-padding);
  }

  &-blue {
    color: #fff;
    background: var(--primary-color);
    border-color: var(--Stroke-Weak);
  }

  &-geekblue {
    color: var(--primary-color);
    background: var(--Fill-Brand-weak);
    border-color: var(--Stroke-Brand-weak);
  }

  &-processing {
    color: #fff;
    background: var(--Brand-Dark-1000);
    border-color: var(--Stroke-Weak);
  }

  &-green {
    color: var(--ant-success-color);
    background: var(--Fill-Success-weak);
    border-color: var(--Stroke-Success-weak);
  }

  &-red {
    color: var(--ant-error-color);
    background: var(--Fill-Error-weak);
    border-color: var(--Stroke-Error-weak);
  }

  &-cyan {
    color: var(--Text-Strong);
    background: var(--Fill-Weak);
    border-color: var(--Stroke-Weak);
  }

  &-yellow {
    color: var(--Text-Strong);
    background: var(--Yellow-solid-80);
    border-color: var(--Yellow-solid-500);
  }

  &-purple {
    color: var(--Text-Strong);
    background: var(--Purple-Dark-200);
    border-color: var(--Purple-Dark-800);
  }
}
