@mixin ant-label {
  color: var(--Text-Strong);
  font-size: var(--default-font-size);
}

.ant-form {
  &-vertical {
    .ant-form-item {
      &-label {
        padding: 0 0 2px;

        > label {
          @include ant-label;
        }
      }
    }
  }

  label {
    @include ant-label;
  }

  &-item-label {
    label {
      small {
        display: flex;
        align-items: center;
        margin-left: 8px;
        font-size: var(--small-font-size);
        opacity: 0.8;

        .ant-btn-link.ant-btn-sm {
          font-size: var(--small-font-size);
          padding: 0;
          height: 18px;
          margin-left: 5px;
          box-shadow: none;
          text-decoration: underline;

          span {
            font-size: var(--small-font-size);
          }
        }
      }
    }
  }

  &-item-explain-error {
    width: 105%;
  }
}
