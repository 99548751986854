:root {
  --primary-color: #004b7b;
  --secondary-color: #1a74a8;
  --secondary-color-20: rgba(26, 116, 168, 0.2);
  --dark-color: #01163b;

  --body-background-color: #f5f6fa;

  --Brand-Light-1000: var(--primary-color);
  --Brand-Dark-1000: #5772af;
  --Teal-Light-800: rgba(26, 116, 168, 0.8); // based on secondary-color
  --Teal-Light-200: rgba(26, 116, 168, 0.2); // based on secondary-color
  --Teal-Dark-200: rgba(126, 192, 229, 0.2);

  --Moxi-orange-gradient: #f9b017;

  --Fill-Weak: rgba(0, 21, 128, 0.04);
  --Fill-Weaker: rgba(0, 21, 128, 0.02);

  --Fill-Information-strong: var(--secondary-color);
  --Fill-Information-weak: rgba(38, 176, 255, 0.05);

  --Fill-Error-strong: #c73a3a;
  --Fill-Error-weak: #fff6f5; //rgba(255, 74, 74, 0.05);
  --Fill-Error-hover: color-mix(in srgb, var(--Fill-Error-strong), #fff 30%);

  --Fill-Success-strong: #067a57;
  --Fill-Success-weak: rgba(10, 204, 146, 0.05);

  --Fill-Overlay: rgba(0, 13, 77, 0.45);
  --Fill-Brand-weak: rgba(0, 75, 123, 0.05);

  --Fill-Inverse-disabled: rgba(255, 255, 255, 0.12);

  --Yellow-solid-1000: #fec62e;
  --Yellow-solid-500: rgb(254, 198, 46, 0.5);
  --Yellow-solid-80: rgb(254, 198, 46, 0.08);

  --Yellow-bright-solid-1000: #ffe404;

  --Orange-solid-1000: #f7ad1a;

  --Purple-Dark-100: rgba(210, 178, 255, 0.1);
  --Purple-Dark-200: rgba(210, 178, 255, 0.2);
  --Purple-Dark-800: rgba(210, 178, 255, 0.8);
  --Purple-Dark: rgb(210, 178, 255);

  // Typography
  --Text-Strong: rgba(0, 6, 38, 0.9);
  --Text-Weak: rgba(0, 9, 51, 0.65);
  --Text-Error: var(--Fill-Error-strong);
  --Text-Brand: var(--primary-color);
  --Text-Success: var(--Fill-Success-strong);

  --h1-font-size: 2.5rem;
  --h4-font-size: 1.25rem;
  --h5-font-size: 1rem;
  --h6-font-size: 0.875rem;

  --default-font-size: 16px;
  --small-font-size: 14px;

  // Strokes
  --Stroke-Strong: var(--Fill-Overlay);
  --Stroke-Weak: rgba(0, 17, 102, 0.1);
  --Stroke-Information-strong: rgba(26, 116, 168, 0.8);
  --Stroke-Information-weak: rgba(26, 116, 168, 0.2);
  --Stroke-Error-strong: rgba(199, 58, 58, 0.8);
  --Stroke-Error-weak: rgba(199, 58, 58, 0.14);
  --Stroke-Brand-weak: rgba(0, 75, 123, 0.2);
  --Stroke-Success-weak: rgba(6, 122, 87, 0.2);

  // Layout
  --header-height: 64px;
  --footer-height: 60px;
  --side-menu-width: 256px;
  --side-menu-collapsed-width: 80px;
  --side-menu-font-size: 16px;
  --default-gap: 32px;

  // Breakpoints
  --xs-breakpoint: 599.98px;
  --sm-breakpoint: 959.98px;
  --md-breakpoint: 1099.98px;

  // Sizes
  --xl--border-radius: 16px;
  --lg--border-radius: 12px;
  --md--border-radius: 8px;
  --sm--border-radius: 8px;
  --lg-height: 56px;
  --md-height: 48px;
  --sm-height: 32px;
  --lg-font-size: 20px;
  --md-font-size: 16px;
  --sm-font-size: 14px;
  --xl-padding: 32px;
  --lg-padding: 24px;
  --md-padding: 16px;
  --sm-padding: 12px;
  --mobile-padding: 15px;

  // Button
  --btn-small-width: 40px;

  // Cards
  --card-border-radius: 16px;

  // Tags
  --tag-border-radius: 16px;
  --tag-padding: 10px;

  // Checkbox
  --checkbox-size: 24px;
  --checkbox-border-radius: 4px;

  // Icon
  --Icon-Neutral: var(--Fill-Overlay);

  // Input
  --input-icon-size: 22px;

  // Table
  --table-height: calc(100vh - 400px);

  // Modal
  --modal-border-radius: 16px;
}
