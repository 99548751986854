$xs-breakpoint: 599.98px;
$sm-breakpoint: 959.98px;
$md-breakpoint: 1099.98px;

/* SCSS Helpers */
@mixin box-shadow {
  box-shadow:
    0px 2px 4px -2px rgba(0, 0, 0, 0.08),
    0px 4px 8px -2px rgba(0, 0, 0, 0.04);
}

@mixin container-style {
  @include box-shadow;

  padding: 20px;
  border-radius: 10px;
  background: #fff;
}

@mixin align-items-center {
  display: flex;
  align-items: center;
  text-decoration: none;
}

@mixin font-rendering {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  -moz-font-smoothing: antialiased !important;
  text-rendering: optimizelegibility !important;
}

/* Ant Cards */
@mixin ant-card-head-colors($light, $dark) {
  .ant-card-head {
    background: $light;
    border-color: $dark;

    .anticon {
      color: $dark;
    }
  }
}

@mixin element-colours($bg, $border, $text) {
  background: $bg;
  border: 1px solid $border;
  color: $text;
}

/* Media Query Mixins */
@mixin media-query($breakpoint, $orientation: null) {
  @if $orientation == null {
    @media screen and (max-width: $breakpoint) {
      @content;
    }
  } @else {
    @media screen and (max-width: $breakpoint) and (orientation: $orientation) {
      @content;
    }
  }
}

@mixin mobile {
  @include media-query($xs-breakpoint, portrait) {
    @content;
  }
}

@mixin md-breakpoint {
  @include media-query($md-breakpoint) {
    @content;
  }
}

@mixin landscape {
  @media screen and (orientation: landscape) {
    @content;
  }
}
