@mixin table-header {
  tr {
    th {
      color: unset;
      background: #fff;

      &.ant-table-cell-fix-right {
        &::after {
          box-shadow: none;
        }
      }

      @include table-actions;
      @include checkbox-position;

      th.actions {
        padding: 6px 8px;
      }
    }

    &.light-header {
      th {
        background: var(--Fill-Weak);
      }
    }
  }
}

@mixin table-body {
  tr {
    td {
      width: 100%;

      &:first-of-type {
        color: var(--Text-Strong);
      }

      @include table-actions;
      @include checkbox-position;
    }

    &.highlight {
      &--alert {
        td {
          background: #fff1f0;
        }
      }
      &--warning {
        td {
          background: #fffbe6;
        }
      }
      &--info {
        td {
          background: #eef9ff;
        }
      }
      &--success {
        td {
          background: #f6ffed;
        }
      }
      &--disable {
        td {
          background: #f9f9f9;
          color: var(--Text-Weak);
        }
      }
    }
  }
}

@mixin action-cell {
  text-align: center;
  white-space: nowrap;
  padding-left: 0;
  padding-right: 0;
}

@mixin table-actions {
  &.actions {
    @include action-cell;

    .ant-btn-text.ant-btn-icon-only {
      height: 36px;
      width: 36px;
      color: var(--Icon-Neutral);

      .anticon {
        font-size: 24px;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        .anticon {
          color: var(--secondary-color);
        }
      }

      &.dark {
        color: var(--primary-color);
      }
    }
  }
}

@mixin checkbox-position {
  &:has(.ant-checkbox-wrapper) {
    .ant-checkbox {
      top: 7px;
    }
  }
}

.ant-table {
  font-size: var(--default-font-size);
  color: var(--Text-Weak);
  width: 100%;

  &-thead {
    @include table-header;
  }

  &-tbody {
    @include table-body;
  }

  &-header {
    thead {
      @include table-header;
    }
  }

  &-body {
    overflow-y: auto !important; // override the default overflow

    tbody {
      @include table-body;
    }
  }

  &-footer {
    background: transparent;
  }

  &.ant-table-small {
    .ant-table-thead {
      @include table-header;
    }

    .ant-table-tbody {
      tr {
        td {
          font-size: var(--small-font-size);

          &.actions {
            @include action-cell;

            .ant-btn-text.ant-btn-icon-only {
              scale: 0.85;
            }
          }

          .ant-checkbox {
            scale: 0.85;
          }
        }
      }
    }

    .ant-table-placeholder {
      & > td.nz-disable-td {
        padding: 0;
      }
    }

    .ant-empty-normal {
      margin: 10px 0 0 0;

      .ant-empty-image {
        height: 20px;
        margin-bottom: 0;
      }

      .ant-empty-description {
        margin-bottom: 5px;
      }
    }

    &:has(.ant-table-placeholder) {
      margin-bottom: 30px;
    }
  }

  .nz-table-hide-scrollbar {
    scrollbar-color: transparent transparent;
  }
}
