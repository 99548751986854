// Add new grid classes for 2xl screens
@media (min-width: 2000px) {
  @for $i from 1 through 24 {
    .ant-col-2xl-#{$i} {
      display: block;
      flex: 0 0 (100% / 24 * $i);
      max-width: (100% / 24 * $i);
    }
  }
}
