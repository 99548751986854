.ant-select {
  &:not(.ant-select-customize-input) &-selector,
  &-single:not(&-customize-input) &-selector {
    border-radius: var(--md--border-radius);
    height: var(--md-height);
    font-size: var(--md-font-size);
    border: 1px solid var(--Stroke-Strong);

    &:hover {
      border-color: var(--secondary-color);
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item,
    .ant-select-selection-search-input {
      height: calc(var(--md-height) - 2px);
      display: flex;
      align-items: center;
    }
  }

  &-arrow {
    .anticon {
      font-size: 16px;
    }
  }

  &-item,
  &-item-empty {
    font-size: 16px;
  }

  &-dropdown {
    border-radius: 0 0 var(--md--border-radius) var(--md--border-radius);
  }
}
